<template>
  <div>
    <div id="map"></div>

    <div class="card">
      <div class="header">
        <div class="title">任务详情</div>
      </div>

      <div class="list">
        <div class="item">
          <div class="label">任务单号</div>
          <div class="value">{{ text.number }}</div>
        </div>
        <div class="item">
          <div class="label">任务名称</div>
          <div class="value">{{ text.name }}</div>
        </div>
        <div class="item">
          <div class="label">任务类型</div>
          <div class="value">
            <DataDictFinder dictType="taskType" :dictValue="text.type" />
          </div>
        </div>

        <div class="item">
          <div class="label">任务日期</div>
          <div class="value">{{ text.taskTime }}</div>
        </div>
        <div class="item">
          <div class="label">执行人</div>
          <div class="value">
            <span v-if="text.executor">
              {{
                userList.find((item) => item.id === text.executor)
                  ? userList.find((item) => item.id === text.executor).name
                  : "--"
              }}
            </span>
          </div>
        </div>

        <div class="item">
          <div class="label">任务说明</div>
          <div class="value">{{ text.remark }}</div>
        </div>

        <div class="item">
          <div class="label">任务状态</div>
          <div class="value">
            <DataDictFinder dictType="taskStatus" :dictValue="text.status" />
          </div>
        </div>
      </div>

      <div class="table-title">设备列表</div>

      <div class="table-wrapper">
        <a-table
          bordered
          size="small"
          :data-source="facilityList"
          rowKey="id"
          :pagination="false"
        >
          <a-table-column title="编号" data-index="id"></a-table-column>
          <a-table-column title="类型" align="center">
            <template slot-scope="text">
              <span v-if="text.category === 'sewage'">污水</span>
              <span v-if="text.category === 'rain'">雨水</span>
              <span v-if="text.type === 'well'">检查井</span>
              <span v-else-if="text.type === 'pipe'">管线</span>
              <span v-else>
                <DataDictFinder
                  dictType="deviceType"
                  :dictValue="text.type"
                  iconType="text"
                />
              </span>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <div class="table-title">评分</div>

      <div style="padding: 0 1vw">
        <a-slider v-model="score" />
      </div>

      <div class="table-title">评分标准</div>

      <div style="padding: 1vh 1vw; color: #999; font-size: 0.8em">
        <div>60分以下差，60-80分一般，80-90分良好，90-100分优秀。</div>
      </div>

      <div class="center" style="padding: 2vh 0 4vh">
        <a-space>
          <a-button @click="$router.go(-1)">关闭</a-button>
          <a-button type="primary" @click="pass" :loading="saveLoading"
            >审核通过</a-button
          >
        </a-space>
      </div>
    </div>

    <a-modal
      title="打卡详情"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
    >
      <div class="detail">
        <div class="item">
          <div class="label">打卡时间</div>
          <div class="value">{{ detail.signTime }}</div>
        </div>
        <div class="item">
          <div class="label">打卡图片</div>
          <div class="images" v-if="typeof detail.pictures === 'string'">
            <a
              v-for="item in detail.pictures.split(',')"
              :key="item"
              :href="item.indexOf('http') === 0 ? item : 'https://' + item"
              target="_blank"
            >
              <img
                :src="item.indexOf('http') === 0 ? item : 'https://' + item"
                alt
              />
            </a>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { fetchDetail } from "../api/task";
import { fetchList } from "@/api/setting/user";

import { Scene, PointLayer, LineLayer } from "@antv/l7";
import { GaodeMapV2 } from "@antv/l7-maps";

import request from "@/api/request";

function done(data) {
  return request({
    url: "/model-analysis/inspectTask/reviewTask",
    data,
    method: "post",
  });
}

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      userList: [],
      text: {},

      facilityList: [],
      tracksList: [],

      detail: {},
      visible: false,

      score: 0,
      saveLoading: false,
    };
  },

  mounted() {
    this.initMap();

    fetchList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (res && Array.isArray(res.list)) {
        this.userList = Object.freeze(res.list);
      }
    });

    const { id, type } = this.$route.query;
    if (id && type) {
      fetchDetail({ id, type }).then((res) => {
        console.log("detial", res);
        if (res) {
          this.text = res;
          if (Array.isArray(res.facilityList)) {
            this.facilityList = res.facilityList.map((item) => {
              return {
                ...item,
                id: item.deviceId,
                startLnglat: item.xfrom ? [item.xfrom, item.yfrom] : undefined,
                endLnglat: item.xto ? [item.xto, item.yto] : undefined,
              };
            });
          }

          if (Array.isArray(res.tracksList) && res.tracksList.length > 0) {
            // this.tracksList = res.tracksList;
            this.setLine(res.tracksList);
            this.setPointer(res.tracksList);
            this.map.setCenter([res.tracksList[0].x, res.tracksList[0].y]);
          }
        }
      });
    }
  },
  beforeDestroy() {
    this.scene?.destroy();
  },

  methods: {
    // ...mapActions("home", ["getFacilityList"]),
    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }

      var map = new AMap.Map("map", {
        center: [119.056708, 33.583976],
        zoom: 20,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
      });

      this.map = map;

      this.scene = new Scene({
        id: "map",
        logoVisible: false,
        map: new GaodeMapV2({
          mapInstance: map,
        }),
      });
    },

    setPointer(list) {
      this.pointer = new PointLayer({
        zIndex: 200,
        name: "pointer",
      })
        .source(list, {
          parser: {
            type: "json",
            x: "x",
            y: "y",
          },
        })
        .shape("simple")
        .size(8)
        .color("#f00");

      this.pointer.on("click", this.pointLayerClick);

      this.scene?.addLayer(this.pointer);
    },
    pointLayerClick(e) {
      console.log("e", e);
      this.detail = e.feature;
      this.visible = true;
    },
    cancel() {
      this.detail = {};
      this.visible = false;
    },

    setLine(list) {
      const sources = {
        type: "FeatureCollection",
        name: "dl2",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "MultiLineString",
              coordinates: [
                list.map((item) => {
                  return [item.x, item.y];
                }),
              ],
            },
          },
        ],
      };

      this.pathLayer = new LineLayer({
        zIndex: 100,
        depth: true,
      })
        .source(sources)
        .size(3)
        .shape("line")
        .texture("arrow")
        .color("rgb(22,119,255)")
        .animate({
          interval: 5, // 间隔
          duration: 1, // 持续时间，延时
          trailLength: 2, // 流线长度
        })
        .style({
          opacity: 0.6,
          lineTexture: true, // 开启线的贴图功能
          iconStep: 10, // 设置贴图纹理的间距
          borderWidth: 0.4, // 默认文 0，最大有效值为 0.5
          borderColor: "#fff", // 默认为 #ccc
        });

      this.scene?.addLayer(this.pathLayer);
    },

    pass() {
      console.log("this.score", this.score);
      const that = this;
      this.$confirm({
        title: `是否继续？`,
        onOk() {
          const hide = that.$message.loading("加载中...", 0);
          done({
            ...that.text,
            status: "done",
            score: that.score,
          })
            .then(() => {
              that.$router.go(-1);
            })
            .finally(() => {
              hide();
            });
        },
      });
    },
  },
};
</script>


<style lang="less" scoped>
#map {
  position: fixed;
  height: 105vh;
  width: 100vw;
  left: 0;
  top: 0;
}

.card {
  position: fixed;
  top: 12vh;
  right: 4vw;
  z-index: 99;
  width: 50vw;
  background-color: #000;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 998;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  padding: 2vh;

  .item {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}

.table-title {
  font-weight: bold;
  padding: 0 2vh;
}

.table-wrapper {
  padding: 2vh;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 2vh;

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .value {
      font-weight: bold;
    }

    .images {
      display: flex;
      align-items: center;
      gap: 1vh;

      img {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }
}
</style>
